import { gql } from "@apollo/client";

export const GET_PARTY_TYPE = gql`
  query GetPartyType {
    party_types {
      id
      name
      is_active
    }
  }
`;

export const GET_PARTIES = gql`
  query GetParties {
    parties {
      id
      name
      is_active
      type_id
      address
      type
      party_type {
        id
        name
      }
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      first_name
      last_name
      email
      mobile
      is_social
      is_active
      is_emailverified
      address
      parties {
        id
        name
      }
    }
  }
`;

export const GET_ROLES = gql`
  query GetRoles($id: Int) {
    roles(id: $id) {
      id
      name
      is_active
      party_id
      product_id
      product_name {
        id
        name
      }
      party_name {
        id
        name
      }
    }
  }
`;

export const GET_PRODUCTS = gql`
  query GetProducts($id: Int) {
    product(id: $id) {
      id
      name
      description
      is_active
    }
  }
`;

export const GET_PERMISSIONS = gql`
  query GetPermissions {
    permissions {
      id
      name
      parentid
      is_active
    }
  }
`;

export const GET_MODULES = gql`
  query GetModules {
    modules {
      id
      name
      is_active
      permissions {
        id
        name
        is_active
      }
    }
  }
`;

export const GET_ROLE_PERMISSIONS = gql`
  query GetRolePermissions($id: Int!) {
    rolepermissions(id: $id) {
      name
      is_active
      permissions {
        id
        name
        is_active
      }
    }
  }
`;

export const GET_USER_PARTY = gql`
  query GetUserParty($user_id: Int!) {
    userParty(user_id: $user_id) {
      id
      name
      party_id
      user_id
      user_type_id
      roles {
        id
        name
      }
    }
  }
`;

export const GET_PARTY_ROLES = gql`
  query GetPartyRoles($id: Int!) {
    partyRole(id: $id) {
      id
      name
    }
  }
`;
