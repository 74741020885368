import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";
import {
  TextField,
  makeStyles,
  useTheme,
  FormControl,
  Button,
  CssBaseline,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Container,
  Paper,
  Typography,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  Checkbox,
  TableRow,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMutation, useQuery } from "@apollo/client";
import { AddAlertContext } from "../../common/store";
import { addPartyComponent } from "../../styles/styles";
import { DEL_USER_PARTY } from "../../utils/graphQL/mutations";
import { GET_USER_PARTY, GET_PARTY_ROLES } from "../../utils/graphQL/queries";
import Spinners from "../common/spinners";
import ConfirmationDialog from "../common/confirmationDialog";
import passwordGen from "../../common/randomPassword";
import TransportFlow from "./transportFlow";
import Dyn from "./components/checkbox";
import Togg from "./components/togg";
import Togg1 from "./components/addEditRoleTogg";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
    flexGrow: 1,
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  formGroup: {
    "& > *": {
      margin: theme.spacing(1),
      //   width: 200
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    paddingLeft: "40px",
    paddingRight: "40px",
    width: "70%",
    margin: "auto",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  submitIcon: {
    margin: theme.spacing(2, 0, 2),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

function AddUserParty(props) {
  const { user } = props;
  const classes = useStyles();
  const theme = useTheme();
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    address: "",
  };
  const [typeName, setTypeName] = useState(initialState);
  const [bol, setBol] = useState();
  const [optionsValue, setoptionsValue] = useState();
  const [open, setOpen] = useState(false);
  const [spinners, setSpinners] = useState(false);
  const [deleteUserParty, { loading: l1 }] = useMutation(DEL_USER_PARTY);
  const [PartyState, setPartyState] = useState();
  let { loading, error, data, refetch } = useQuery(GET_USER_PARTY, {
    variables: { user_id: user.id },
  });
  const {
    loading: rolesLoading,
    error: rolesError,
    data: rolesData,
    refetch: rolesRefetch,
  } = useQuery(GET_PARTY_ROLES, {
    variables: { id: PartyState },
    fetchPolicy: "no-cache",
  });
  const [errMessage, seterrMessage] = useState("");
  const [addAlertValue, setAddAlertValue] = useContext(AddAlertContext);
  const [caption, setCaption] = useState("");
  const [autoPassword, setAutoPassword] = useState();
  const [expanded, setExpanded] = React.useState(false);
  const [dopen, setdOpen] = useState(false);
  const [delParty, setdelParty] = useState();
  const [ccl, setccl] = useState(true);
  const [modulesApollo, setModulesApollo] = useState();
  const [Userstate, setUserstate] = useState(false);

  useEffect(() => {
    const randomPassword = passwordGen();
    setAutoPassword(randomPassword);
  }, []);

  useEffect(() => {}, [props.user]);

  useEffect(() => {}, [data]);

  useEffect(() => {}, [rolesLoading]);

  useEffect(() => {
    refetch(GET_USER_PARTY);
    return () => {};
  }, [Userstate]);

  const handleDelClose = () => {
    setdOpen(false);
  };

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await deleteUserParty({
      variables: { id: delParty },
      refetchQueries: [
        { query: GET_USER_PARTY, variables: { user_id: user.id } },
      ],
      awaitRefetchQueries: true,
    });
    if (response) handleDelClose();
  };

  const handleClickDelete = async (e, row) => {
    e.preventDefault();
    setdelParty(row.id);
    setdOpen(true);
  };

  const handleCheck = (e, aa) => {
    e.preventDefault();
    setccl(e.target.checked);
  };

  const handleEdit = (e, row) => {
    e.preventDefault();
    setPartyState(row.party_id);
    setModulesApollo(rolesData);
  };

  const getAvailableRoles = (allRoles, userRoles) => {
    const availableRoles = allRoles.filter(function (role) {
      for (let i = 0; i < userRoles.roles.length; i++) {
        if (allRoles.id === userRoles.roles.id) {
          return false;
        }
      }
      return true;
    });

    return availableRoles;
  };

  return (
    <div>
      <Container component="main">
        <div style={{ color: "red" }}>{errMessage}</div>
        <CssBaseline />
        <Paper className={classes.paper} elevation={5}>
          <Typography component="h1" variant="h5">
            User & Party Association
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid Item xs={12}>
                <span>
                  <h3>User Info</h3>
                </span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="firstName"
                  value={user.first_name}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lastName"
                  value={user.last_name}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={user.email}
                  disabled={true}
                />
              </Grid>
            </Grid>
            <br />
            <Divider variant="middle" />
            <br />
            <Grid container spacing={2} alignItems="center">
              <Grid>
                <span>
                  <h3>User - Party Association</h3>
                </span>
              </Grid>

              <Grid item xs={12} style={{ marginLeft: "auto" }}>
                <TransportFlow user={user} />
              </Grid>
            </Grid>
            <br />
            <Divider variant="middle" />
            <Grid container>
              <span>
                <h3>List of Associated Parties</h3>
              </span>
            </Grid>
            <Grid container>
              {!loading
                ? data.userParty.map((row) => (
                    // <li key = {row.id}>{row.name}</li>
                    <Accordion
                      expanded={expanded === row.name}
                      onChange={handleChangeAccordion(row.name)}
                      style={{ width: "inherit" }}
                      // onClick={(e) => handleEdit(e, row)}
                    >
                      <Grid item xs={12}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className={classes.heading}>
                            {row.name}
                          </Typography>
                          <Typography className={classes.secondaryHeading}>
                            User Type -{" "}
                            {row.user_type_id === 1 ? "Employee" : "Customer"}
                          </Typography>
                          <EditIcon
                            color="primary"
                            aria-label="add"
                            style={{ marginLeft: "auto" }}
                            // className={classes.IconSize}
                            onClick={(e) => handleEdit(e, row)}
                          />
                          <DeleteIcon
                            color="secondary"
                            aria-label="add"
                            style={{ marginLeft: "15px" }}
                            // className={classes.IconSize}
                            onClick={(e) => handleClickDelete(e, row)}
                          />
                        </AccordionSummary>
                      </Grid>
                      <Grid item xs={12}>
                        <AccordionDetails display="block !important">
                          <Typography align="inherit" h4>
                            List of Assigned Roles
                          </Typography>
                        </AccordionDetails>

                        <AccordionDetails display="block !important">
                          <TableContainer style={{ padding: "15px" }}>
                            <Table
                              size="small"
                              aria-label="a dense table"
                              stickyHeader
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>Role Name</TableCell>
                                  <TableCell>Status</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {row &&
                                  row.roles.map((r, index) => (
                                    <TableRow>
                                      <TableCell component="th" scope="row">
                                        {r.name}
                                      </TableCell>
                                      <TableCell>
                                        <Togg
                                          rowMap={r}
                                          isChecked={true}
                                          row={row}
                                          setUserstate={setUserstate}
                                          Userstate={Userstate}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </TableBody>
                            </Table>
                          </TableContainer>

                          {/* {row &&
                            row.roles.map((r, index) => (
                              <Togg rowMap={r} isChecked={true} row={row} />
                            ))} */}
                        </AccordionDetails>
                      </Grid>
                      <Grid item xs={12}>
                        <AccordionDetails>
                          <Typography align="inherit" h4>
                            Assign New Roles
                          </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                          {/* {rolesLoading ? (
                            <span>Loading</span>
                          ) : rolesData ? (
                            rolesData && (
                              <Togg1
                                allRoles={rolesData.partyRole}
                                userRoles={row}
                                isChecked={false}
                              />
                            )
                          ) : (
                            "NUll"
                          )} */}
                          <Grid item xs={12}>
                            {row.user_type_id == 1 ? (
                              rolesData && (
                                <Togg1
                                  allRoles={rolesData.partyRole}
                                  userRoles={row.roles}
                                  userDetails={row}
                                  setUserstate={setUserstate}
                                  Userstate={Userstate}
                                />
                              )
                            ) : (
                              <TableContainer style={{ padding: "15px" }}>
                                <Table
                                  size="small"
                                  aria-label="a dense table"
                                  stickyHeader
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Role Name</TableCell>
                                      <TableCell>Status</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>No Roles</TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </Grid>

                          {/* {row && (
                            <Grid item xs>
                              <Togg1 rowMap={row.roles} isChecked={true} />
                            </Grid>
                          )} */}
                        </AccordionDetails>
                      </Grid>
                    </Accordion>
                  ))
                : "Please Wait or Refresh again"}
            </Grid>
            {/* <Grid container>
              <TransportFlow user={user} />
            </Grid> */}
            <Grid
              container
              justify="flex-end"
              spacing={2}
              // className={classes.submitIcon}
            >
              {/* <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  style={{ marginRight: "10px" }}
                  className={classes.button}
                  onClick={handleClickClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={classes.button}
                  onClick={handleClickOpen}
                >
                  Save
                </Button>
              </Grid> */}
            </Grid>
            <ConfirmationDialog
              open={dopen}
              handleClose={handleDelClose}
              handleSubmit={handleSubmit}
              dialogTitle="Are you sure you want to Delete User Party Association ?"
            />
          </form>
        </Paper>
      </Container>
    </div>
  );
}
export default forwardRef(AddUserParty);
